:root {
  --box-shadow--: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  --link--hover--: #1888b9;
  --color-white--: #ffffff;
  --light-gray--: #d3d3d3;
  --btn-dark--: #000;
}

.custome_num_input::-webkit-outer-spin-button,
.custome_num_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.custome_num_input {
  -moz-appearance: textfield;
}

/* loading */
.loading_center {
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* header-section-start */
.header-right .notification_dropdown .dropdown-toggle::after {
  display: none;
}

.filter_btn,
.clear_filter_btn,
.add_btn {
  border-radius: 20px !important;
  font-size: 18px;
  font-weight: 600;
  margin-left: 20px;
  height: 40px;
  align-items: center;
  display: flex;
}

.clear_filter_btn {
  /* background-color: #efefef; */
  border-radius: 20px !important;
  font-size: 18px;
  margin-left: 20px;
  font-weight: 600;
}

.btn-dark:focus,
.btn-success:focus,
.btn-primary:focus,
.btn-danger:focus,
.btn-dark:active,
.btn-success:active,
.btn-primary:active,
.btn-danger:active {
  box-shadow: none;
  outline: none !important;
}

.btn-dark:active:focus,
.btn-danger:active:focus,
.btn-primary:active:focus,
.btn-success:active:focus,
.btn-light:active:focus {
  box-shadow: none;
  outline: none !important;
}

.btn:focus,
button:focus,
.btn-close:focus {
  box-shadow: none;
  outline: none !important;
}

.custom-switch label {
  font-weight: 400;
}

/* add-modal-present-now */
.model .flaticon-381-search-2 {
  font-size: 10px;
  border: 1px solid lightgray;
  /* padding: 3px; */
  border-radius: 20px;
  color: black;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.model small {
  margin: 4px;
}

.custom-upload-file [type="file"]::-webkit-file-upload-button {
  display: none;
  opacity: 0;
}

.custom-upload-file [type="file"] {
  padding: 100px;
  border: 2px dashed #000;
}

/* .category-edit-img .close {
  width: 25px;
  border-radius: 20px;
  background: black;
  color: white;
  box-shadow: 0px 2px 5px lightgrey;
  position: absolute;
  right: 20px;
  top: 155px;
} */

.add-purchase-item tr td:last-child span {
  margin: auto;
  border: 1px solid red;
  width: 30px;
  text-align: center;
  border-radius: 30px;
  padding: 6px;
  margin: auto;
  position: relative;
  left: 60px;
  display: inline-grid;
  cursor: pointer;
}

.add-purchase-item tr td:last-child span i {
  color: red;
  text-shadow: 0px 0px 1px;
}

.add-purchase .btn-square {
  background: #efefef !important;
  color: #000;
  border-radius: 5px;
  border-color: #b1b1b1;
  border: 1px solid #b1b1b1;
}

.add-purchase .btn-square i {
  background: #1eaae7;
  padding: 6px;
  border-radius: 20px;
  margin-right: 5px;
  color: white;
}

.add-purchase .btn-square:focus {
  box-shadow: none;
}

.add-purchase .btn-square:active {
  color: #000;
  border-color: #b1b1b1;
}

.add-purchase-item tr td:first-of-type {
  width: 40%;
}

.add-purchase-item tr td:nth-child(2),
.add-purchase-item tr td:nth-child(3),
.add-purchase-item tr td:nth-child(4),
.add-purchase-item tr td:last-of-type {
  width: 15%;
}

.add-purchase-item tr td:nth-child(3) .form-control {
  border: none;
  background-color: #efefef;
}

.rdt_Table {
  color: #000;
  border-radius: 0.625rem;
  overflow: hidden;
  border-bottom: 0;
}

.rdt_Table .rdt_TableCol {
  font-size: 16px;
  color: #000;
  white-space: nowrap;
  font-weight: bold;
}

.rdt_Table .rdt_TableRow {
  background-color: #ffffff;
}

.rdt_Table .rdt_TableCell {
  font-size: 16px;
  padding: 10px 10px;
}

.rdt_Table input[type="checkbox"] {
  border-radius: 0.25em;
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-color: rebeccapurple !important;

  margin-top: 0.25em;
  vertical-align: top;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rebeccapurple !important;
  /* appearance: none; */
  /* color-adjust: exact; */
}

.rdt_Table input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' strokeWidth='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  background-color: var(#00838f) !important;
  border-color: var(#00838f) !important;
}

@media screen and (max-width: 1400px) {
  .rdt_Table .rdt_TableCol {
    font-size: 18px;
  }
  .rdt_Table .rdt_TableCell {
    padding: 25px 0px;
    font-size: 14px;
  }
}

/* product-filter-offcanvas */
.filter_wrapper {
  padding: 15px;
  /* overflow-y: auto;
  height: 500px; */
}

.filter_title {
  font-size: 25px;
  font-weight: 600;
  /* letter-spacing: 2px; */
  text-shadow: 0px 2px 3px lightgrey;
  cursor: pointer;
}

.filter_header {
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
}

.filter_select_all_label {
  margin: 2px 10px 0px 10px;
  font-size: 17px;
  margin-bottom: 5px;
  font-weight: 700;
}

.filter_element {
  margin-bottom: 5px;
}

.filter_element .form-label {
  align-items: center;
  display: flex;
  margin: 2px 10px 0px 10px;
}

.filter_footer {
  /* position: absolute; */
  /* width: 100%; */
  /* bottom: 0px; */
  /* box-shadow: 1px 2px 1rem lightgrey; */
  /* background-color: white; */
  padding: 10px;
  box-shadow: none !important;
}

.apply_filter {
  width: 110px;
  border-radius: 30px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-item {
  border-radius: 0;
}

.accordion-header {
  padding: 0;
}

.accordion-button:not(.collapsed) {
  /* color: transparent; */
  background: transparent;
  box-shadow: none;
}

/* map_spare_parts_model_table start */
.component_img_wrap {
  /* width: 500px; */
  height: 400px;
  box-shadow: 0px 1px 2px lightgrey;
}

.component_img_wrap img {
  width: 100%;
}

.map_compoent_wrap {
  /* width: 500px;
  height: 500px; */
}

.map_compoent_wrap img {
  width: 100%;
}

.map_spare_parts_model_table {
  box-shadow: 0px 3px 10px lightgrey;
  cursor: pointer;
}

.map_spare_parts_model_table table {
  /* width: max-content; */
}

.table-responsive .dropdown-menu.show {
  /* transform: translate(0px, -50px) !important; */
  transform: translate(-0px, -50px) !important;
}

.model_image_view_wrap img {
  width: 100%;
}

.view_maker_wrap {
  padding: 20px;
}

.view_maker_wrap p {
  line-height: normal;
}

.view_maker_brand_image img {
  width: 20%;
}

.view_componant_image_wrap img {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* /map-components-image-co-ordinates/ */
.image-cordinates {
  /* position: absolute; */
  /* top: 408px;
  bottom: 84px; */
  border: 1px solid yellow;
  width: 20px;
  height: 20px;
  color: #000000;
  text-align: center;
  cursor: pointer;
  line-height: 18px;
  background-color: yellow;
}

.image-cordinates_hoverd {
  background-color: rgb(167, 80, 80);
  color: #ffffff;
  border: 1px solid #000000;
}

/* .image-cordinates:hover {
  background-color: red;
  color: #ffffff;
  border: 1px solid #000000;
} */

.mapped_component_table_hovered {
  background-color: rgb(167, 80, 80);
  color: #ffffff;
}

.profile_update_btn {
  width: 100px;
  margin: 20px 0px 0px 160px;
}

.part_image_upload_wrapper .la-times {
  position: absolute;
  right: -10px;
  width: 20px;
  height: 20px;
  /* align-items: center; */
  /* display: flex; */
  line-height: 20px;
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
  /* box-shadow: 0px 0px 2px lightgrey; */
  top: -7px;
  background: #000000;
  color: white;
}

.view_part_img img {
  height: 100%;
}

.image-upload {
  position: relative;
  border: 1px dashed #d6d6d6;
  padding: 5px 0 0;
  border-radius: 4px;
  text-align: center;
  min-width: 0;
  width: 250px;
  height: 210px;
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.hv-centered {
  position: absolute;
  display: inline-table;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#upload {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

#upload input[type="file"] {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#upload input[type="file"]:after {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.image-upload .filesize-info {
  position: absolute;
  bottom: 10px;
  left: 0;
  line-height: 15px;
}

.font-small {
  font-size: 13px;
}

.image-upload .drag-text {
  margin-bottom: 0;
  padding-top: 5px;
}

.hv-centered svg {
  width: 50px;
  fill: #999;
}

.image-list-container.image-list-creation-container {
  max-width: 247px;
}

.image-list-container .image-list-item {
  display: inline-block;
  border: 1px solid #eee;
  min-height: 43px;
  min-width: 42px;
  padding: 5px;
  margin: 0 1.5px 4px;
  position: relative;
  border-radius: 4px;
}

.image-list-container .image-highlight {
  border: 1px solid #2fa3e7;
}

.image-list-container .image-list-item {
  display: inline-block;
  border: 1px solid #eee;
  min-height: 43px;
  min-width: 42px;
  padding: 5px;
  margin: 10px 1.5px 4px;
  position: relative;
  border-radius: 4px;
}

.image-list-container .add-image-section .add-image-svg {
  position: absolute;
  top: 42%;
  left: 50%;
  height: 40%;
  width: 50%;
  cursor: pointer;
  margin: -15% 0 0 -25%;
}

.image-list-container .add-image-section #edit {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  left: 0;
  height: 43px;
  top: 0;
  right: 0;
}

.after_upload_img {
  width: 230px;
  height: 170px;
}

.after_upload_img img {
  width: 100%;
  height: 100%;
}

.small_img {
  width: 45px;
  height: 45px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.small_img img {
  width: 30px;
  height: 30px;
}

.active_hightlight_img {
  border: 1px solid #2fa3e7 !important;
}

.make_default_img_section {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.make_default_img_section * {
  cursor: pointer;
}

.make_default_img_section span {
  font-size: 12px;
  text-transform: capitalize;
  color: rgb(43, 193, 85);
}

.make_default_img_section i {
  font-size: 13px;
  color: rgb(254, 128, 36);
}

.wizard_card_body_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.wizard_card_body_wrapper img {
  height: 100px;
}

.wizard_card_body_wrapper a {
  margin-top: 25px;
  justify-content: center;
  display: flex;
}

.wizard_card_body_wrapper:hover a {
  text-decoration: underline;
  color: var(--link--hover--);
}

.wizard_card_wrapper .card {
  height: 250px;
}

.add_wizard_card,
.wizard_card_load_more {
  cursor: pointer;
}

.add_wizard_wrapper .fa-plus {
  font-size: 20px;
  border: 1px solid;
  border-radius: 20px;
  width: 100xp;
  width: 30px;
  text-align: center;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.add_wizard_card:hover * {
  color: var(--link--hover--);
}

.add_wizard_wrapper .fa-link {
  font-size: 15px;
  border: 1px solid;
  border-radius: 20px;
  width: 100xp;
  width: 30px;
  text-align: center;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.wizard_card_load_more_wrapper .fa-arrow-right {
  font-size: 20px;
  border: 1px solid;
  border-radius: 20px;
  width: 100xp;
  width: 30px;
  text-align: center;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

.wizard_card_header {
  padding: 10px 20px;
  display: flex;
  justify-content: end;
  border: 0;
}

.wizard_card_header .fa-pencil,
.wizard_card_header .fa-eye {
  cursor: pointer;
  font-size: 14px;
}

.wizard_card_header .fa-eye:hover,
.fa-pencil:hover {
  color: var(--link--hover--);
}

.custome_num_input::-webkit-outer-spin-button,
.custome_num_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.custome_num_input {
  -moz-appearance: textfield;
}

.seller_card {
  font-size: 15px !important ;
}
.wallet_dropdown .dropdown-toggle::after {
  display: none;
}

#react-select-41-listbox {
  z-index: 1 !important;
}

.change_password_wrap {
  position: relative;
}

.change_password_wrap .fa-eye-slash,
.change_password_wrap .fa-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.password_wrap {
  position: relative;
}

.password_wrap .fa-eye-slash {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.password_wrap .fa-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.confirm_password_wrap {
  position: relative;
}

.confirm_password_wrap .fa-eye-slash {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 13px;
}

.confirm_password_wrap .fa-eye {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 13px;
  cursor: pointer;
}

.custom_tab_card .custom_tab_card_body .custom_tab_heading {
  /* display: flex !important;
  flex-wrap: nowrap !important;
  white-space: pre !important;
  overflow-x: scroll !important; */
}

::-webkit-scrollbar {
  /* background: #3498db;
  height: 5px;
  border-radius: 15px; */
}

.custome_row img {
  width: 75px;
  height: 65px;
  object-fit: contain;
}

.wizard_add_part_component_locations_table {
  display: block;
  height: 200px;
  overflow-y: scroll;
  /* width: 100%; */
  text-align: center;
}

.wizard_add_part_component_locations_table .fa-trash {
  cursor: pointer;
}

/* /staff-admin/ */
/* .password_wrap{
  position: relative;
}
.password_wrap .fa-eye{
  position: absolute;
  right: 0;
} */

/* /seller-approval-screen/ */
.custom_upload_btn,
.custom_cancel_btn,
.custom_remove_btn {
  height: 30px;
  text-decoration: blink;
  align-items: center;
  display: flex;
  padding: 10px;
  color: var(c);
}

/* no_mapped_componant_image-wrapper */
.no_mapped_componant_image {
  height: 250px;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* no_mapped_componant_image-wrapper */

/* map-componant-part-image-selected-locations */
.selected_componant_locations {
  width: 400px;
  height: 400px;
}

.selected_componant_locations img {
  width: 100%;
}

/* pagination-start */

/* //view_image_wrapper// */
.view_image-wrapper {
  width: 250px;
  height: 180px;
  margin: auto;
  display: flex;
  justify-content: center;
}

/* /admin-login-page/ */
.login_password-wrapper {
  position: relative;
}

.login_password-wrapper .fa-eye,
.login_password-wrapper .fa-eye-slash {
  position: absolute;
  right: 12px;
  bottom: 12px;
  cursor: pointer;
}

/* /current-image-wrapper/ */
.current_img_wrapper {
  width: 230px;
  height: 150px;
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-style: dashed;
  box-shadow: 2px 2px 5px var(--light-gray--);
  overflow: hidden;
  padding: 5px;
}

.current_img_wrapper img {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

/* //filter-section// */
.filter_content-section {
  /* margin-left: 30px; */
  margin-bottom: 30px;
}

.filter_content-section .filter_element {
  display: flex;
}

/* /part-compatability-table-section */
.remove_compatability {
  cursor: pointer;
}

.remove_compatability:hover {
  opacity: 0.8;
}
/* /part-compatability-table-section */

/* /map-component-of-part-image-wrapper/ */
.mapped_component_image_wrapper {
  width: 460px;
  height: 320px;
  position: relative;
}

.mapped_component_image_wrapper img {
  width: 455px;
  height: 310px;
  /* box-shadow: 0px 1px 4px lightgray; */
}

.view_mapped_component_table_list {
  cursor: pointer;
  text-align: center;
}

/* dashboard-filter-sales-analysis */
.sales_analysis_filter {
  cursor: pointer;
}
